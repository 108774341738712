<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('TBS-Digitalisation-grant-website-banner_991-x-331_C-1140x380.jpg');
          background-size: contain;
        "
      >
        <div
          style="    vertical-align: bottom;
          height: 142px;
    padding: 50px 15px;"
        ></div>
      </div>
    </div>

    <b-container class="sales-enquiry">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="page-content">
            <div class="row">
              <div class="col-12 text-center">
                <b-button
                  :to="{ name: 'Enquiry' }"
                  variant="outline-primary"
                  class="mr-1"
                  >Enquiry</b-button
                >
                <b-button
                  :to="{ name: 'Apply' }"
                  variant="outline-primary"
                  class="mr-1"
                  >Apply Now</b-button
                >
                <b-button
                  href="Customer Check List V1.0.pdf"
                  target="_blank"
                  variant="outline-primary"
                  class="mr-1"
                  >Download Now</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SMEGrant"
};
</script>
